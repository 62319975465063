import React from "react";
import styles from "./index.module.css";

const artists1 = [
  {
    image: "/images/artists/RickyMartin.png",
    mark: "/images/artists/backstage_logo.png",
    name: "Ricky Martin",
    content:
      "Ricky Martin, a Puerto Rican singer, actor, and author, captivates audiences with his dynamic performances and infectious energy. Known for hits like “Livin' la Vida Loca,” he is an international icon, blending Latin pop with charisma, talent, and a powerful voice that resonates worldwide.",
  },
  {
    image: "/images/artists/Enrique_logo.png",
    mark: "/images/artists/backstage_logo.png",
    name: "Enrique Iglesias",
    content:
      "Enrique Iglesias is a Spanish singer, songwriter, and actor known for his Latin pop music. With over 180 million records sold worldwide, he is one of the best-selling Spanish artists of all time. He has also won numerous awards for his music, including several Grammy Awards.",
  },
  {
    image: "/images/artists/Martin.png",
    mark: "/images/artists/Haya_logo.png",
    name: "Martin Garrix",
    content:
      "Martin Garrix is a Dutch DJ, record producer, and musician. He is best known for his hit song “Animals,” which became a massive international success in 2013. Garrix has won several awards and has been named the world's number one DJ by DJ Mag multiple times.",
  },
  {
    image: "/images/artists/artist2.png",
    // mark: "/images/artists/Haya_logo.png",
    name: "Fuerza Bruta",
    content:
      "A mesmerizing live show event that combines thrilling acrobatics, immersive visuals, and pulsating music for an unforgettable experience",
  },
  {
    image: "/images/artists/ha-ash_photo_main 2.png",
    mark: "/images/artists/-GSftIfw 2 (Traced).png",
    name: "Ha*Ash",
    content:
      "Ha*Ash is a highly acclaimed pop duo formed by the talented sisters Hanna and Ashley. They are renowned for their soulful vocals and intricate harmonies. With over 15 million records sold worldwide, the “Queens of Sold-out” have become one of the most successful Latin music acts to date.",
  },
];

const artists2 = [
  {
    image: "/images/artists/Hauser.png",
    mark: "/images/artists/Haya_logo1.png",
    name: "Hauser",
    description:
      "Hauser is a Croatian cellist and songwriter, best known as a member of the 2Cellos duo. He has released solo albums and collaborated with artists such as Andrea Bocelli and Elton John.",
  },
  {
    image: "/images/artists/item13.png",
    mark: "/images/artists/logo_starting_dark 1.png",
    name: "KOVACS",
    description:
      "A Dutch singer from Baarlo, Sharon Kovacs is known for two successful studio albums, Shades of Black and Cheap Smells, as well as hit singles such as “Snake Charmer”, “Crazy” and “Tutti Frutti Tequila”.",
  },
  {
    image: "/images/artists/Tyga.png",
    mark: "/images/artists/Haya_logo1.png",
    name: "Tyga",
    description:
      "Tyga is an American rapper, singer, and songwriter. He rose to fame with his hit single “Rack City” in 2011 and has since released multiple successful albums.",
  },
  {
    image: "/images/artists/item15.png",
    mark: "/images/artists/logo_starting_dark 1.png",
    name: "Harry Potter Symphonic Orchestra",
    description:
      "Fans around the globe can experience the magic of Harry Potter with a live orchestra performance. While the film plays on a screen, a full symphony orchestra performs, bringing the scenes to life before your eyes.",
  },
  {
    image: "/images/artists/item16.png",
    mark: "/images/artists/PHP Logo 1.png",
    name: "Benny Dayal",
    description:
      "Benny Dayal is a renowned Indian artist known for his soulful vocals and dynamic stage presence. With hits like “Badtameez Dil” and “Bang Bang,” he has captivated audiences with his versatile singing style, making him a prominent figure in the Indian music industry.",
  },
  {
    image: "/images/artists/item17.png",
    mark: "/images/artists/logo_starting_dark 1.png",
    name: "BUIKA",
    description:
      "Concha Buika is one of the best Spanish singers of our time. In 2008, her album, Niña de Fuego, was nominated for the Latin Grammy Award for Album of the Year. In 2014, La Noche Más Larga was nominated for Best Latin Jazz Album.",
  },
  {
    image: "/images/artists/FIreofAnatolia.png",
    mark: "",
    name: "Fire of Anatolia",
    description:
      "The Fire of Anatolia or Anadolu Ateşi is a Turkish dance group consisting of 120 dancers. The group has performed in more than 85 countries from the United States to China and Japan, in front of an audience of approximately 20 million people.",
  },
  {
    image: "/images/artists/item18.png",
    mark: "/images/artists/logo_starting_dark 1.png",
    name: "Jacky Terrasson",
    description:
      "A French Jazz pianist and composer, Jacky Terrasson has been said to have a shining improvisational imagination. In 1993, he won the Thelonious Monk International Jazz Piano Competition.",
  },
  {
    image: "/images/artists/item19.png",
    mark: "/images/artists/logo_starting_dark 1.png",
    name: "Louis Winsberg",
    description:
      " One of the first in France to use the synth guitar, Winsberg is a prominent figure in the French jazz scene and has toured all over the world, from Europe to Japan to the USA.",
  },
  {
    image: "/images/artists/item10.png",
    mark: "/images/artists/logo_starting_dark 1.png",
    name: "Alessandro Safina",
    description:
      "Trained as an opera singer at an early age, Alessandro Safina discovered pop music as a young man and has since blended the two into a style all his own, dubbed “pop-op” by music critics.",
  },
  {
    image: "/images/artists/item14.png",
    mark: "/images/artists/nd.png",
    name: "Luciano",
    description:
      "A Chilean and Swiss DJ and producer, Luciano has been named Best Tech House DJ five times. Thanks to his efforts, electronic music has become more popular in Chile.",
  },
  {
    image: "/images/artists/Gipsy.png",
    mark: "/images/artists/logo_starting_dark 1.png",
    name: "Gipsy Kings",
    description:
      "A group of flamenco, salsa, and pop musicians from southern France, the Gipsy Kings perform mostly in Catalan while mixing in Spanish and southern French dialects",
  },
];

function Artist1(props) {
  const { item, position } = props;
  return (
    <div className={styles.artist1_wrapper}>
      <div
        className={`${styles.artist1_content_wrapper} ${
          position === "right" ? styles.artist1_content_wrapper_right : ""
        }`}
      >
        <div
          className={`${styles.artist1_content} ${
            position === "_right" ? styles.artist1_content_right : ""
          }`}
        >
          <img src={item.mark} alt="" />
          <div className={styles.artist1_name}>{item.name}</div>
          <div className={styles.artist1_description}>{item.content}</div>
        </div>
      </div>
      <div
        className={`${styles.artist1_image_wrapper} ${
          position === "right" ? styles.artist1_image_wrapper_right : ""
        }`}
      >
        <img className={styles.artist1_image} src={item.image} alt="" />
      </div>
    </div>
  );
}

function Artists1() {
  return (
    <div className={styles.artists1}>
      {artists1.map((artist, i) => (
        <Artist1
          key={artist.name}
          item={artist}
          position={i % 2 === 0 ? "left" : "right"}
        />
      ))}
    </div>
  );
}

function Artist2(props) {
  const item = props.item;
  return (
    <div className={styles.artist2}>
      <img className={styles.artist2_image} src={item.image} alt="" />
      <div className={styles.artist2_mark_contain}>
        <img className={styles.artist2_mark} src={item.mark} alt="" />
      </div>
      <div
        className={styles.artist2_name}
      >
        {item.name}
      </div>
      <div className={styles.artist2_description}>{item.description}</div>
    </div>
  );
}

function Artists2() {
  return (
    <div className={styles.artists2}>
      {artists2.map((artist) => (
        <Artist2 key={artist.name} item={artist} />
      ))}
    </div>
  );
}

function ArtistPartners() {
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.title}>Backstage</div>
        <Artists1 />
        <Artists2 />
        <div className={styles.manymore}>
          ...and many more A-list celebrities, artists, venues <br /> and brands
          coming soon!
        </div>
      </div>
    </div>
  );
}

export default ArtistPartners;

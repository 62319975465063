import React from "react";
import { useGlobalStateContext } from "../../../context";
import styles from "./index.module.css";

const items = [
  {
    image: "/images/future_items/marketplace-icon.png",
    title: "Marketplace",
    width: "35%",
    content:
      "The BKS Marketplace unifies all parties, enabling album releases, Smart Ticket sales, and even networking & dealmaking.",
  },
  {
    image: "/images/future_items/AI.png",
    title: "AI Tech",
    width: "60%",
    content:
      "AI technology empowers artists, managers, and promoters with informed decisions for live events, benefiting both industry professionals and fans through enhanced experiences and unforgettable memories.",
  },
  {
    image: "/images/future_items/POS.png",
    title: "BiKOS PoS",
    width: "47%",
    content:
      "The Backstage PoS allows businesses to accept crypto anytime, anywhere, as well as get instant liquidity through BKS token buybacks.",
  },
  {
    image: "/images/future_items/NFT-ticket.png",
    title: "RWAs Tickets for Events and Experiences",
    width: "47%",
    content:
      "Unique Smart Tickets offer a new way for venues and artists to connect with fans, along with lower fees and better margins.",
  },
  {
    image: "/images/future_items/Launch.png",
    title: "Launchpad",
    width: "30%",
    content:
      "A fundraising platform for the industry — the meeting place for event organizers, venues and customers.",
  },
  {
    image: "/images/future_items/metaverse.png",
    title: "Metaverse Events",
    width: "30%",
    content:
      "The BKS Metaverse enables universal access to event venues, from stadiums to concerts. Host hybrid events, meet artists, follow your team & more.",
  },
  {
    image: "/images/future_items/Wallet.png",
    title: "BKS Wallet",
    width: "30%",
    content:
      "Seamlessly and securely integrate crypto into business operations, exchange BKS tokens and store Real-World Assets.",
  },
];

function Item(props) {
  const item = props.item;
  return (
    <div className={styles.item_wrapper} style={{width: item.width}}>
      {/* <img
        className={styles.item_back}
        src="/images/future_items/item_back.png"
        alt=""
      /> */}
      <div className={styles.item}>
        <div className={styles.item_padding}>
          <img
            className={styles.item_image}
            src={item.image}
            alt={item.title}
          />
          <div className={styles.item_title}>{item.title}</div>
          <div className={styles.item_content}>{item.content}</div>
        </div>
      </div>
    </div>
  );
}

function FutureItems() {
  return (
    <div className={styles.items_box}>
      {items.map((item) => (
        <Item key={item.title} item={item} />
      ))}
    </div>
  );
}

function FutureEvents({ fRef }) {
  const { isMobile } = useGlobalStateContext();

  return (
    <div className={styles.future_wrapper}>
      <div className={styles.future}>
        <div className={styles.future_text} ref={fRef}>
          The Future of Events is {!isMobile && <br />} Powered by Blockchain
        </div>
        <FutureItems />
      </div>
    </div>
  );
}

export default FutureEvents;

/* eslint-disable array-callback-return */
import React from "react";
import styles from "./index.module.css";

function Time() {
  const data = [
    {
      value: "$1.135T",
      text: "Events market in 2021",
      maxWidth: 262,
    },
    {
      value: "$60B",
      text: "Online ticketing market by 2025",
      maxWidth: 200,
    },
    {
      value: "$23B",
      text: "Real-World Assets Market",
      maxWidth: 250,
    },
    {
      value: "300M",
      text: "Crypto users",
      maxWidth: 250,
    },
    {
      value: "$104B",
      text: "Creator economy",
      maxWidth: 250,
      sup: "/year",
    },
  ];

  const uniques = [
    {
      img: "noUpfront",
      title: "One Stop Shop",
      text: "Backstage provide assistance and support throughout the entire lifecycle of the product",
    },
    {
      img: "revenueShare",
      title: "Revenue Share",
      text: "Immediate revenue share provided by smart contracts",
    },
    {
      img: "tailerMade",
      title: "Tailor-made",
      text: "Custom made, unique to your business model",
    },
    {
      img: "userFriendly",
      title: "User Friendly",
      text: "Quick to learn, simple to use, accessible technology",
    },
  ]

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div>
          <p className={styles.title}>The Time Is Now</p>
        </div>
        <div className={styles.time_block}>
          <div className={styles.time_container}>
            {data.map((item, index) => (
              <div className={styles.item} key={index}>
                <p className={styles.item_value}>
                  {item.value}
                  <sup className={styles.sup}>{item.sup}</sup>
                </p>
                <p
                  className={styles.item_text}
                  style={{ maxWidth: item.maxWidth }}
                >
                  {item.text}
                </p>
              </div>
            ))}
          </div>
          <div className={styles.badge}>
            Capturing a portion of this market will result in a large buyback
            for Backstage
          </div>
        </div>
        <div>
          <p className={styles.unique_title}>Unique Features</p>
        </div>
        <div className={styles.unique_block}>
          {
            uniques.map((item, index) => (
              <div key={index} className={styles.unique_item}>
                <div>
                  <img
                    src={"/images/time/" + item.img + ".png"}
                    alt=""
                    className={styles.features_img}
                  />
                </div>
                <p className={styles.unique_item_title}>{item.title}</p>
                <p className={styles.unique_item_text}>{item.text}</p>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
}

export default Time;

/* eslint-disable react/jsx-pascal-case */
import React from "react";
import Footer from "../../../components/footer";
import { useGlobalStateContext } from "../../../context";
import styles from "./index.module.css";

function Roadmap() {
  const { isMobile } = useGlobalStateContext();

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <Footer isMobile={isMobile} />
      </div>
    </div>
  );
}

export default Roadmap;

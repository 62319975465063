import React from "react";
import { Link } from "react-router-dom";
import styles from "./index.module.css";
import {
  FaceBookIcon,
  FOCUS_STATE,
  GithubIcon,
  InstagramIcon,
  MediumIcon,
  NONE_STATE,
  TelegramIcon,
  TwitterIcon,
  MailIcon,
} from "../Icons";

const menus = [
  {
    title: "Marketplace",
    route: "https://backstage.global",
  },
  {
    title: "Whitepaper",
    route: "/Backstage_WP.pdf",
  },
  {
    title: "Terms and Conditions",
    route: "/TERMS-OF-USE-AND-PRIVACY POLICY-Bksbackstage.pdf",
  },
];

const links = [
  {
    image: MailIcon,
    route: "mailto:stage@bksbackstage.io",
    title: "Email",
  },
  {
    image: TelegramIcon,
    route: "https://t.me/BKSBackstage",
    title: "Telegram",
  },
  {
    image: TwitterIcon,
    route: "https://twitter.com/BackstageBks",
    title: "Twitter",
  },
  {
    image: InstagramIcon,
    route: "https://www.instagram.com/bksbackstage/?hl=en",
    title: "Instagram",
  },
  {
    image: GithubIcon,
    route: "https://github.com/BKSbackstage",
    title: "Github",
  },
  {
    image: FaceBookIcon,
    route: "https://www.facebook.com/BKSBackstage",
    title: "Facebook",
  },
  {
    image: MediumIcon,
    route: "https://bksbackstageofficial.medium.com/",
    title: "Medium",
  },
];

function Logo() {
  return (
    <Link to="/">
      <div className={styles.logo_sect}>
        <img className={styles.logo_image} src="/logo.png" alt="logo" />
        {/* <div className={styles.logo_title}>BACKSTAGE</div> */}
      </div>
    </Link>
  );
}

function Menu() {
  return (
    <div className={styles.menus_wrapper}>
      <div className={styles.menu_title}>Links</div>
      <div className={styles.menus}>
        {menus.map((item, index) => (
          <div className={styles.menu} key={index}>
            <a href={item.route} target="_blank" rel="noreferrer">
              {item.title}
            </a>
          </div>
        ))}
      </div>
    </div>
  );
}

function Links({isMobile}) {
  const [focus, setFocus] = React.useState(0);
  return (
    <div className={styles.menus_wrapper}>
      <div className={styles.menu_title}>Connect With Us</div>
      <div className={styles.links}>
        <div className={styles.links_sub}>
          { !isMobile && [0, 1, 2].map((id) => {
            const IconImage = links[id].image;
            return (
              <a
                href={links[id].route}
                target="_blank"
                rel="noreferrer"
                key={links[id].image}
              >
                <div
                  className={styles.link_sect}
                  onMouseOver={() => {
                    setFocus(id + 1);
                  }}
                  onMouseOut={() => setFocus(0)}
                >
                  <IconImage
                    className={styles.link_image}
                    color={focus === id + 1 ? FOCUS_STATE : NONE_STATE}
                  />
                  <div className={styles.menu_text}>{links[id].title}</div>
                </div>
              </a>
            );
          })}
          { isMobile && [0, 1, 2, 6].map((id) => {
            const IconImage = links[id].image;
            return (
              <a
                href={links[id].route}
                target="_blank"
                rel="noreferrer"
                key={links[id].image}
              >
                <div
                  className={styles.link_sect}
                  onMouseOver={() => {
                    setFocus(id + 1);
                  }}
                  onMouseOut={() => setFocus(0)}
                >
                  <IconImage
                    className={styles.link_image}
                    color={focus === id + 1 ? FOCUS_STATE : NONE_STATE}
                  />
                  <div className={styles.menu_text}>{links[id].title}</div>
                </div>
              </a>
            );
          })}
        </div>
        <div className={styles.links_sub}>
          {[3, 4, 5].map((id) => {
            const IconImage = links[id].image;
            return (
              <a
                href={links[id].route}
                target="_blank"
                rel="noreferrer"
                key={links[id].image}
              >
                <div
                  className={styles.link_sect}
                  key={links[id].image}
                  onMouseOver={() => {
                    setFocus(id + 1);
                  }}
                  onMouseOut={() => setFocus(0)}
                >
                  <IconImage
                    className={styles.link_image}
                    color={focus === id + 1 ? FOCUS_STATE : NONE_STATE}
                  />
                  <div className={styles.menu_text}>{links[id].title}</div>
                </div>
              </a>
            );
          })}
        </div>
        <div className={styles.links_sub}>
          { !isMobile && [6].map((id) => {
              const IconImage = links[id].image;
              return (
                <a
                  href={links[id].route}
                  target="_blank"
                  rel="noreferrer"
                  key={links[id].image}
                >
                  <div
                    className={styles.link_sect}
                    key={links[id].image}
                    onMouseOver={() => {
                      setFocus(id + 1);
                    }}
                    onMouseOut={() => setFocus(0)}
                  >
                    <IconImage
                      className={styles.link_image}
                      color={focus === id + 1 ? FOCUS_STATE : NONE_STATE}
                    />
                    <div className={styles.menu_text}>{links[id].title}</div>
                  </div>
                </a>
              );
            })}
        </div>
      </div>
    </div>
  );
}

function Footer({isMobile}) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <Logo />
        <Menu />
        <Links isMobile={isMobile} />
      </div>
      <div className={styles.bottom}>
        <div className="">
          ALL RIGHTS RESERVED. <br />
          DESIGNED AND DEVELOPED BY BACKSTAGE.
          <br /> <br />
          { isMobile && 'TERMS AND POLICY' }
        </div>
      </div>
    </div>
  );
}

export default Footer;

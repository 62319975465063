/* eslint-disable array-callback-return */
import React from "react";
import { useGlobalStateContext } from "../../../context";
import styles from "./index.module.css";

function SmartTickets() {
  const { isMobile } = useGlobalStateContext();
  const business_data = [
    {
      img: "Lower-fees",
      text: "Achieve lower fees & efficient, transparent transactions",
      maxWidth: 275,
    },
    {
      img: "piechart",
      text: "Eliminate intermediaries",
      maxWidth: 230,
    },
    {
      img: "Upsell",
      text: "Upsell merchandise & other perks",
      maxWidth: 219,
    },
    {
      img: "Engagement",
      text: "Increase engagement with your customers",
      maxWidth: 241,
    },
    {
      img: "protect",
      text: "Eliminate counterfeiting & price gouging",
      maxWidth: 243,
    },
    {
      img: "revenue",
      text: "Create new revenue streams",
      maxWidth: 243,
    },
  ];
  const user_data = [
    {
      img: "Royalty",
      text: "Secure royalties from future sales",
      maxWidth: 275,
    },
    {
      img: "Ticket",
      text: "Own the rights of your ticket",
      maxWidth: 230,
    },
    {
      img: "rewards",
      text: "Expand your rewards",
      maxWidth: 219,
    },
    {
      img: "Collect",
      text: "Collect limited memorabilia",
      maxWidth: 241,
    },
    {
      img: "Gain",
      text: "Gain control of secondary market",
      maxWidth: 243,
    },
    {
      img: "Unlock",
      text: "Unlock unique experiences",
      maxWidth: 243,
    },
  ];
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div>
          <p className={styles.title}>Real-World Assets Ticket Experiences</p>
        </div>
        <div className={styles.benefit_block}>
          <div className={styles.benefit_title}>Business Benefits</div>
          <div className={styles.benefit_items}>
            {business_data.map((item, index) => (
              <div key={index} className={styles.item}>
                <div>
                  <img
                    src={"/images/smartTicket/" + item.img + ".png"}
                    alt=""
                    className={styles.item_img}
                  />
                </div>
                <p
                  className={styles.description}
                  style={{ maxWidth: item.maxWidth }}
                >
                  {item.text}
                </p>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.benefit_block}>
          <div className={styles.benefit_title}>User Benefits</div>
          <div className={styles.benefit_items}>
            {user_data.map((item, index) => (
              <div key={index} className={styles.item}>
                <div>
                  <img
                    src={"/images/smartTicket/" + item.img + ".png"}
                    alt=""
                  />
                </div>
                <p
                  className={styles.description}
                  style={{ maxWidth: item.maxWidth }}
                >
                  {item.text}
                </p>
              </div>
            ))}
          </div>
        </div>
        <div style={{ marginTop: 104 }}>
          <p className={styles.title}>Simple and Easy To Use</p>
          <div className={styles.use_container}>
            <div className={styles.use_item}>
              <img
                src={"/images/smartTicket/user-plus.png"}
                alt=""
                style={{ width: 68, height: 60 }}
              />
              <p className={styles.use_text}>Register on the marketplace</p>
            </div>
            <div className={styles.next_left}>
              <img src={"/images/smartTicket/next.png"} alt="" />
            </div>
            <div className={styles.next_down}>
              <img src={"/images/smartTicket/next-down.png"} alt="" />
            </div>
            <div className={styles.use_item}>
              <img
                src={"/images/smartTicket/purchase.png"}
                alt=""
                style={{ width: 68, height: 60 }}
              />
              <p className={styles.use_text}>Purchase with FIAT or crypto</p>
            </div>
            <div className={styles.next_left}>
              <img src={"/images/smartTicket/next.png"} alt="" />
            </div>
            <div className={styles.next_down}>
              <img src={"/images/smartTicket/next-down.png"} alt="" />
            </div>
            <div className={styles.use_item} style={{ width: 222 }}>
              <img
                src={"/images/smartTicket/confirm.png"}
                alt=""
                style={{ width: 68, height: 68 }}
              />
              <p className={styles.use_text} style={{ marginTop: 20 }}>
                Receive the purchase confirmation code
              </p>
            </div>
            <div className={styles.next_left}>
              <img src={"/images/smartTicket/next.png"} alt="" />
            </div>
            <div className={styles.next_down}>
              <img src={"/images/smartTicket/next-down.png"} alt="" />
            </div>
            <div className={styles.use_item}>
              <img
                src={"/images/smartTicket/mint.png"}
                alt=""
                style={{ width: 68, height: 68 }}
              />
              <p className={styles.use_text} style={{ marginTop: 20 }}>
                Claim and store the digital ítem on your wallet
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SmartTickets;

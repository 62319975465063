const FOCUS_STATE = "#6A4DFD";
const NONE_STATE = "white";

const TwitterIcon = ({ color, className }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.43156 3.9502L9.85673 13.8782L2.38477 21.9502H4.06654L10.6084 14.8829L15.8938 21.9502H21.6166L13.7734 11.4638L20.7284 3.9502H19.0466L13.0221 10.4588L8.1543 3.9502H2.43156ZM4.90468 5.18886H7.53368L19.1431 20.7115H16.5141L4.90468 5.18886Z"
      fill="white"
      stroke={color}
      opacity={0.8}
    />
  </svg>
);

const MediumIcon = ({ color, className }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24.5 17.2"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.1,1.4L2.1,1.4l1.2,1.4l0.1,0.1l0,0.2l0,5.1c0,1.4,0,2.7,0,3.7c0,0.5,0,0.9,0,1.2c0,0.1,0,0.3,0,0.4
      c0,0,0,0.1,0,0.1l0,0c0,0,0,0.1,0,0.2c0,0.1-0.1,0.2-0.1,0.2c0,0-0.1,0.1-0.1,0.2c-0.1,0.1-0.2,0.3-0.3,0.4c-0.3,0.3-0.6,0.7-1,1.1
      c-0.3,0.4-0.7,0.7-0.9,1c0.5,0,1.4,0,2.8,0c1.3,0,2.2,0,2.7,0c-0.2-0.3-0.6-0.7-0.9-1L4.2,14l-0.1-0.1l0-0.2l0-4.2c0-1.2,0-2.2,0-3
      c0-0.4,0-0.7,0-0.9c0-0.1,0-0.2,0-0.3c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0.1-0.2
      c0.2-0.1,0.3-0.2,0.5-0.1c0.1,0,0.2,0.1,0.2,0.1C5,4.8,5,4.8,5,4.9l0,0c0,0,0,0,0,0.1l0,0c0,0,0,0,0,0c0,0,0,0.1,0.1,0.1
      c0,0.1,0.1,0.2,0.2,0.3C5.5,5.7,5.7,6.1,6,6.7c0.6,1.1,1.3,2.6,2.1,4.2h0l2.9,5.7l0.1,0l0.1,0l2.4-5.5l0,0C14.3,9.5,15,8,15.4,6.9
      c0.2-0.5,0.4-1,0.6-1.3c0.1-0.3,0.2-0.5,0.3-0.6c0,0,0.1-0.1,0.2-0.2c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.3,0.1
      C17.1,4.9,17.2,5,17.2,5c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.1c0,0.1,0,0.2,0,0.3c0,0.2,0,0.6,0,1c0,0.9,0,2.1,0,3.4l0,4.9l0,0.2
      l-0.2,0.1l-1.1,0.9c-0.2,0.1-0.3,0.3-0.4,0.4c0.7,0,1.8,0,3.8,0c2,0,3.2,0,3.8,0c-0.1-0.1-0.2-0.2-0.4-0.3
      c-0.3-0.2-0.5-0.5-0.7-0.7c-0.1-0.1-0.2-0.2-0.2-0.2c0,0-0.1-0.1-0.2-0.2c0-0.1-0.1-0.1-0.1-0.1l0,0c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1
      c0,0,0-0.1,0-0.1c0-0.1,0-0.2,0-0.4c0-0.3,0-0.8,0-1.4c0-1.2,0-2.7,0-4.5l0-6.3l0-0.2l0.2-0.1l1-0.9c0,0,0,0,0,0
      c0.2-0.2,0.4-0.3,0.5-0.5c-0.5,0-1.2,0-2.4,0c-1,0-1.8,0-2.4,0c-0.3,0-0.5,0-0.6,0c0,0,0,0.1,0,0.1c-0.1,0.2-0.2,0.4-0.3,0.7
      c-0.3,0.5-1.2,2.7-2.2,4.8c-0.5,1.1-0.9,2.1-1.3,2.9c-0.2,0.4-0.3,0.7-0.4,1c-0.1,0.1-0.1,0.2-0.1,0.3c0,0,0,0,0,0.1c0,0,0,0,0,0
      l0,0c0,0,0,0,0,0h0 M12.5,10.1 M2.1,1.4 M2.1,1.4C1.8,1.1,1.5,0.8,1.3,0.5c0.5,0,1.4,0,2.7,0h3.2l0.1,0.2C7.4,0.9,8.6,3.3,10,6
       M10.4,5.7 M2.1,1.4 M13.4,10.5 M13.4,10.5C13.4,10.5,13.4,10.5,13.4,10.5 M13.4,10.5c0,0.1-0.2,0.3-0.4,0.3
      c-0.2,0-0.4-0.1-0.4-0.1c-0.1-0.1-0.2-0.2-0.2-0.2c-0.1-0.2-0.3-0.4-0.5-0.8C11.5,9,10.9,7.8,10,6 M10,6C10,6,10,6,10,6l0.4-0.2
       M10,6l0.4-0.2 M12.5,10.1 M10.4,5.7 M16.2,5.4C16.2,5.4,16.2,5.4,16.2,5.4C16.2,5.4,16.2,5.4,16.2,5.4z M18,0.1
      C18,0.1,18,0.2,18,0.1C18,0.2,18,0.1,18,0.1z"
      stroke={color}
    />
  </svg>
);

const FaceBookIcon = ({ color, className }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 2H15C13.6739 2 12.4021 2.52678 11.4645 3.46447C10.5268 4.40215 10 5.67392 10 7V10H7V14H10V22H14V14H17L18 10H14V7C14 6.73478 14.1054 6.48043 14.2929 6.29289C14.4804 6.10536 14.7348 6 15 6H18V2Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      shapeRendering="crispEdges"
    />
  </svg>
);

const InstagramIcon = ({ color, className }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17 2H7C4.23858 2 2 4.23858 2 7V17C2 19.7614 4.23858 22 7 22H17C19.7614 22 22 19.7614 22 17V7C22 4.23858 19.7614 2 17 2Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16 11.37C16.1234 12.2022 15.9812 13.0522 15.5937 13.799C15.2062 14.5458 14.5931 15.1514 13.8416 15.5297C13.0901 15.9079 12.2384 16.0396 11.4077 15.9059C10.5771 15.7723 9.80971 15.3801 9.21479 14.7852C8.61987 14.1902 8.22768 13.4229 8.09402 12.5922C7.96035 11.7615 8.09202 10.9099 8.47028 10.1584C8.84854 9.40685 9.45414 8.79374 10.2009 8.40624C10.9477 8.01874 11.7977 7.87658 12.63 8C13.4789 8.12588 14.2648 8.52146 14.8716 9.1283C15.4785 9.73515 15.8741 10.5211 16 11.37Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.5 6.5H17.51"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const GithubIcon = ({ color, className }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 22V18.13C16.0375 17.6532 15.9731 17.1738 15.811 16.7238C15.6489 16.2738 15.3929 15.8634 15.06 15.52C18.2 15.17 21.5 13.98 21.5 8.52C21.4997 7.12383 20.9627 5.7812 20 4.77C20.4559 3.54851 20.4236 2.19835 19.91 0.999999C19.91 0.999999 18.73 0.649999 16 2.48C13.708 1.85882 11.292 1.85882 9 2.48C6.27 0.649999 5.09 0.999999 5.09 0.999999C4.57638 2.19835 4.54414 3.54851 5 4.77C4.03013 5.7887 3.49252 7.14346 3.5 8.55C3.5 13.97 6.8 15.16 9.94 15.55C9.611 15.89 9.35726 16.2954 9.19531 16.7399C9.03335 17.1844 8.96681 17.6581 9 18.13V22M9 19C4 20.5 4 16.5 2 16L9 19Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const TelegramIcon = ({ color, className }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24.1 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.9,1.2L20.9,1.2c-0.8,0.3-2.2,0.8-3,1.1c-0.8,0.3-1.8,0.7-2.2,0.9c-0.4,0.2-1.8,0.7-3,1.2
		c-2.3,0.9-5.9,2.3-7.8,3C4.3,7.6,3.3,8,2.5,8.3c-0.4,0.1-0.7,0.3-1,0.4C1.4,8.7,1.3,8.8,1.2,8.8c0,0-0.1,0-0.1,0c0,0,0,0,0,0
		c0,0,0,0,0,0h0l0,0C0.7,9.1,0.6,9.2,0.6,9.3c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0.1,0.1,0.2,0.1 M0.8,9.4 M20.9,1.2 M20.9,1.2
		c0.8-0.3,1.6-0.6,1.8-0.7 M20.9,1.2l1.8-0.7 M0.8,9.4C0.8,9.4,0.8,9.4,0.8,9.4 M0.8,9.4L0.8,9.4 M22.7,0.6c0.1,0,0.1,0,0.2-0.1
		c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0.2,0,0.4,0.1,0.5,0.3c0,0.1,0,0.1,0,0.1c0,0.1,0,0.1,0,0.3c0,0.3,0,0.5-0.1,1
		c-0.1,0.4-0.3,1.3-0.4,2c-0.2,0.7-0.4,2-0.6,2.8c-0.4,1.9-0.8,3.8-1.1,5.1c-0.1,0.5-0.4,1.8-0.6,2.8 M20.7,14.9 M22.7,0.6 M2.5,10
		L2.5,10l2.8,0.9h0l1,0.3c0.2-0.1,1-0.6,1.9-1.2c1-0.6,2.9-1.8,4.2-2.6v0c1.3-0.8,3.2-2,4.2-2.6c1.2-0.7,1.8-1.1,2.1-1.3
		c0.2-0.1,0.3-0.2,0.4-0.2c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.3,0,0.5,0c0.1,0,0.2,0.1,0.3,0.2c0.1,0.1,0.1,0.3,0.1,0.4
		c0,0.1,0,0.2,0,0.2c0,0.1-0.1,0.1-0.1,0.2 M2.5,10C2.1,9.9,1.6,9.7,1.3,9.6 M2.5,10L1.3,9.6 M9.6,18.6 M12.8,15.8 M15.9,17.3
		c0.7,0.5,1.4,1.1,1.9,1.4c0.3,0.2,0.5,0.3,0.6,0.4c0.2,0.1,0.4,0.2,0.5,0.3c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2-0.1,0.3-0.2l0,0
		c0.1-0.2,0.2-0.3,0.5-1.5c0.1-0.6,0.4-1.9,0.6-2.9 M20.7,14.9 M15.9,17.3c-0.7-0.5-1.4-1-1.9-1.4c-0.3-0.2-0.5-0.3-0.6-0.4
		c-0.1-0.1-0.1-0.1-0.2-0.1c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.2,0c-0.1,0-0.1,0.1-0.1,0.1l0,0
		c0,0,0,0,0,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0.1c-0.1,0.1-0.1,0.1-0.2,0.2c-0.2,0.2-0.5,0.4-0.8,0.7c-0.1,0-0.1,0.1-0.1,0.1
		c-0.9,0.9-1.3,1.3-1.6,1.5c0-0.5,0.1-1.3,0.2-2.2c0-0.7,0.1-1.3,0.1-1.8c0-0.2,0-0.4,0-0.6c0,0,0,0,0-0.1c0.1-0.1,0.2-0.2,0.3-0.3
		c0.3-0.3,0.7-0.6,1.1-1l0,0c2-1.8,6.7-6.1,7.8-7l0,0c0.2-0.2,0.4-0.4,0.6-0.5C19.9,4.5,20,4.4,20,4.4c0,0,0,0,0.1-0.1l0,0
		c0,0,0,0,0.1-0.1 M1.3,9.6C1.2,9.6,1,9.5,0.9,9.5 M1.3,9.6L0.9,9.5 M0.9,9.5c-0.1,0-0.1,0-0.1,0 M0.9,9.5l-0.1,0"
      stroke={color}
    />
  </svg>
);

const MailIcon = ({ color, className }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 4H20C21.1 4 22 4.9 22 6V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18V6C2 4.9 2.9 4 4 4Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22 6L12 13L2 6"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export {
  TwitterIcon,
  MediumIcon,
  FaceBookIcon,
  InstagramIcon,
  GithubIcon,
  TelegramIcon,
  MailIcon,
  FOCUS_STATE,
  NONE_STATE,
};

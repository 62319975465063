/* eslint-disable array-callback-return */
import React from "react";
import { useGlobalStateContext } from "../../../context";
import styles from "./index.module.css";

const advisors_data = [
  {
  img: '1.png',
  name: 'Giang Diem Quynh',
  desc: 'Founder Suci Blockchain Hub, Founder Coincu Vista Fund, Host of ETHVietnam'
  },
  {
  img: '2.png',
  name: 'Shirly Valge',
  desc: 'COO of Velas'
  },
  {
  img: '3.png',
  name: 'Immanuel Cape',
  desc: 'Crypto & NFT expert'
  },
  {
  img: '4.png',
  name: 'Kim Thurmann',
  desc: 'Highly Experienced Event Marketer Partner of Rekom Group A/S'
  },
  {
  img: '5.png',
  name: 'Nando Nicassio',
  desc: 'Event Industry Entrepreneur'
  },
  {
  img: '6.png',
  name: 'Francisco Lopez',
  desc: 'Technology-Based Entrepreneurship expert'
  },
  {
  img: '7.png',
  name: 'Naor Nurieli',
  desc: 'DJ, Musician & Producer'
  },
  {
  img: '8.png',
  name: 'Mattia Ulivieri',
  desc: 'Event Industry Specialist & Managing Director at Blue Marlin Ibiza'
  },
  {
  img: '9.png',
  name: 'Justin Brothers',
  desc: 'Business Development Professional & experienced Entrepreneur '
  },
  {
  img: '10.png',
  name: 'Derek Sidney',
  desc: 'USA Event Promoter & experienced Entrepreneur'
  },
  {
  img: '11.png',
  name: 'Mike Scalera',
  desc: 'Corporate Marketing Strategist'
  },
  {
  img: '12.png',
  name: 'Daniel Dasic',
  desc: 'Global Business Development Professional & payments expert'
  },
  {
  img: '14.png',
  name: 'Martin Bundgaard',
  desc: 'Entertainment Industry expert'
  },
  {
  img: '15.png',
  name: 'Samer Naouri',
  desc: 'Founder of Story Entertainment Internantional & Logistics Advisor'
  },
  {
    img: '16.png',
    name: 'Simone Castaldi',
    desc: 'Equity derivative trader and strategist broker'
  },
];
function Advisors({ isMobile }) {
  return (
    <div className={styles.advisors}>
      {advisors_data.map((advisor, index) => {
        // if (!isMobile || (advisor !== 37 && advisor !== 40))
        // if (advisor !== 33)
          return (
            <div className={styles.advisor} key={index}>
              <img
                src={"/images/advisors/" + advisor.img}
                alt=""
                className={styles.advisor_img}
              />
              <div className={styles.advisor_name_desc_wrapper}>
                <div className={styles.advisor_name}>
                  {advisor.name}
                </div>
                <div className={styles.advisor_desc}>
                  {advisor.desc}
                </div>
              </div>
            </div>
          );
      })}
    </div>
  );
}

function OurAdvisors() {
  const { isMobile } = useGlobalStateContext();
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.title}>Our Advisors</div>
        <Advisors isMobile={isMobile} />
      </div>
    </div>
  );
}

export default OurAdvisors;
